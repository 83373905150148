import { ComponentProps, ReactNode } from "react";

import TextButton from "../button/TextButton";
import Styled from "./index.styles";

type AlertType = "default" | "Point";

export type { AlertType };

export default function AlertBanner({
  label,
  type = "default",
  textButtonProps,
  className = "",
}: {
  label: ReactNode;
  type?: AlertType;
  textButtonProps?: ComponentProps<typeof TextButton>;
  className?: string;
}) {
  return (
    <Styled.container className={`${className} alert-banner`} type={type}>
      {typeof label === "string" ? (
        <div className="alert-banner-label">{label}</div>
      ) : (
        label
      )}

      {textButtonProps && <TextButton {...textButtonProps} />}
    </Styled.container>
  );
}
