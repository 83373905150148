import AlertBanner from "@sellernote/_sds-v2/src/components/AlertBanner";

import Styled from "./index.styles";

export default function AEOBanner() {
  return (
    <Styled.AEOBanner>
      <AlertBanner
        className="custom-alert-banner"
        label="쉽다가 디지털 포워딩 스타트업 최초로 AEO인증을 취득했습니다."
        textButtonProps={{
          theme: "white",
          fontSize: 14,
          label: "더 알아보기",
          hasUnderline: true,
          handleClick: () =>
            window.open(
              "https://ship-da.com/blog/aeo-%EB%9C%BB-%ED%98%9C%ED%83%9D%EB%B6%80%ED%84%B0-%EA%B4%80%EC%84%B8%EC%B2%AD-%EC%9D%B8%EC%A6%9D-%ED%8F%AC%EC%9B%8C%EB%94%A9-%EC%97%85%EC%B2%B4%EC%9D%98-%EC%9E%A5%EC%A0%90%EC%9D%80--25395",
              "_blank"
            ),
        }}
      />
    </Styled.AEOBanner>
  );
}
